// src/Header.js
import React from 'react';

// Header component displaying the company name
function Header() {
  return (
    <header style={headerStyle}>
      <h1>Weber Digital LLC</h1>
    </header>
  );
}

// Simple styling for the header
const headerStyle = {
  backgroundColor: '#282c34',
  color: 'white',
  padding: '10px 0',
  textAlign: 'center',
  fontFamily: 'Arial, sans-serif'
};

export default Header;
