// src/Footer.js
import React from 'react';

// Footer component displaying contact information
function Footer() {
  return (
    <footer style={footerStyle}>
      <p>Contact us: inquiry@weberdigital.com</p>
    </footer>
  );
}

// Simple styling for the footer
const footerStyle = {
  backgroundColor: '#282c34',
  color: 'white',
  padding: '10px 0',
  textAlign: 'center',
  position: 'fixed',
  width: '100%',
  bottom: '0',
  fontFamily: 'Arial, sans-serif'
};

export default Footer;
