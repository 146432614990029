// src/Home.js
import React from 'react';

// Home component displaying a brief introduction
function Home() {
  return (
    <main style={mainStyle}>
      <p>Welcome to Weber Digital LLC, your trusted partner in computer consulting. We specialize in delivering top-notch IT solutions tailored to meet your business needs.</p>
    </main>
  );
}

// Simple styling for the main content
const mainStyle = {
  padding: '20px',
  fontFamily: 'Arial, sans-serif',
  textAlign: 'center'
};

export default Home;
