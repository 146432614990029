// src/App.js
import React from 'react';
import Header from './Header';
import Home from './Home';
import Footer from './Footer';
import './App.css'; // Optional: you can add global styles here if needed

// Main App component combining Header, Home, and Footer
function App() {
  return (
    <div>
      <Header />
      <Home />
      <Footer />
    </div>
  );
}

export default App;